import {Model} from "../vuemc";

export default class Register extends Model
{
    defaults() {
        return {
            name:         null,
            email:        null,
            password:     null,
            role:         null,
            timezone:     null,
            region:       null,
            terms:        false,
            captchaToken: null
        }
    }
}
